var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MANAGING_WORKSPACE_ID } from "../../../src/constants";
import { useRequest } from '../../hooks/useRequest';
var useExternalJwtAuth = function () {
    var request = useRequest();
    var _a = useState(true), isJwtLoading = _a[0], setIsJwtLoading = _a[1];
    var _b = useState(false), isJwtAuthenticated = _b[0], setIsJwtAuthenticated = _b[1];
    var _c = useState(), userInfo = _c[0], setUserInfo = _c[1];
    var location = useLocation();
    var navigate = useNavigate();
    var authenticate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var params, token, refreshToken, domain, pathParts, systemId, userId, response, token_1, expirationDate, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = new URLSearchParams(location.search);
                    token = params.get("token");
                    refreshToken = params.get("refresh_token");
                    domain = params.get("domain");
                    pathParts = location.pathname.split("/").filter(Boolean);
                    systemId = pathParts[1];
                    userId = pathParts[2];
                    if (!token || !refreshToken || !domain || !systemId || !userId) {
                        console.log("Invalid parameters: missing token, refreshToken, domain, systemId, or userId");
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    setIsJwtLoading(true);
                    return [4 /*yield*/, request.post("/externalAuth/verify-token", {
                            token: token,
                            refreshToken: refreshToken,
                            domain: domain,
                            systemId: systemId,
                            userId: userId,
                            managingWorkspaceId: MANAGING_WORKSPACE_ID,
                        })];
                case 2:
                    response = _a.sent();
                    if (response.status === 200) {
                        setIsJwtAuthenticated(true);
                        setUserInfo(response.data);
                        token_1 = response.data.token;
                        expirationDate = new Date();
                        expirationDate.setMonth(expirationDate.getMonth() + 36); // Expire in 36 months
                        document.cookie = "dw_c_auth=".concat(token_1, "; expires=").concat(expirationDate.toUTCString(), "; path=/; Secure; SameSite=Lax;");
                        navigate("/ai-agents"); // Navigate to home on success
                    }
                    else {
                        console.log("Invalid response status:", response.status);
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error("Failed to authenticate token:", error_1);
                    console.log("Authentication failed, redirecting...");
                    window.location.href = 'https://openid.phonexa.com/auth/customer-login/';
                    return [3 /*break*/, 5];
                case 4:
                    setIsJwtLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var fetchUserInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
        var userInfoResponse, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, request.get("/externalAuth/current-user")];
                case 1:
                    userInfoResponse = _a.sent();
                    if (userInfoResponse.status === 200) {
                        setUserInfo(userInfoResponse.data);
                        setIsJwtAuthenticated(true);
                        setIsJwtLoading(false);
                    }
                    else {
                        setIsJwtAuthenticated(false);
                        setIsJwtLoading(false);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    setIsJwtAuthenticated(false);
                    setIsJwtLoading(false);
                    console.log(error_2);
                    window.location.href = 'https://openid.phonexa.com/auth/customer-login/';
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var logout = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, request.post("/externalAuth/logout")];
                case 1:
                    response = _a.sent();
                    if (response.status === 200 && response.data.logoutRedirectUrl) {
                        // Clear the frontend state
                        setUserInfo(null);
                        setIsJwtAuthenticated(false);
                        // Redirect to the logout URL provided by the server
                        window.location.href = response.data.logoutRedirectUrl;
                    }
                    else {
                        console.error("Logout failed. Invalid response from the server.");
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _a.sent();
                    console.error("Logout request failed:", error_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return {
        isJwtLoading: isJwtLoading,
        isJwtAuthenticated: isJwtAuthenticated,
        userInfo: userInfo,
        fetchUserInfo: fetchUserInfo,
        authenticate: authenticate,
        logout: logout,
    };
};
export default useExternalJwtAuth;
