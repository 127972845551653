var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Typography, Stack, Card, } from "@mui/joy";
import Variables from "./components/Variables";
export default function DataCollection(props) {
    var plugins = props.plugins, invalidFields = props.invalidFields, onNewPlugin = props.onNewPlugin, onPluginNameChange = props.onPluginNameChange, onPluginIsEnabledChange = props.onPluginIsEnabledChange, onPluginVariablePropertyChange = props.onPluginVariablePropertyChange, onPluginNewVariable = props.onPluginNewVariable, onPluginDeleteVariable = props.onPluginDeleteVariable, onPluginActionTypeChange = props.onPluginActionTypeChange, onPluginActionDescriptionChange = props.onPluginActionDescriptionChange, onPluginExecuteAfterChange = props.onPluginExecuteAfterChange, onPluginInjectResultChange = props.onPluginInjectResultChange, onPluginActionArgsPropertyChange = props.onPluginActionArgsPropertyChange, onPluginActionArgsNewHeader = props.onPluginActionArgsNewHeader, onPluginActionArgsUpdateHeader = props.onPluginActionArgsUpdateHeader, onPluginActionArgsDeleteHeader = props.onPluginActionArgsDeleteHeader, onPluginVariableAddEnumValue = props.onPluginVariableAddEnumValue, onPluginVariableUpdateEnumValue = props.onPluginVariableUpdateEnumValue, onPluginVariableDeleteEnumValue = props.onPluginVariableDeleteEnumValue;
    return (_jsxs(_Fragment, { children: [_jsx(Stack, { children: _jsxs(Typography, __assign({ level: "body-sm" }, { children: ["To edit the name, label, or type of fields, you need to modify them in the product settings and sync the changes with AI.", " "] })) }), plugins
                .filter(function (p) { return p.pluginType === "DataCollectionPlugin"; })
                .map(function (plugin, pluginIndex) { return (_jsx(React.Fragment, { children: _jsx(Card, { children: plugin.isEnabled && (_jsx(_Fragment, { children: _jsx(Stack, __assign({ spacing: 3 }, { children: _jsx(Variables, { variables: plugin.pluginVariables, pluginIndex: pluginIndex, invalidFields: invalidFields, onPluginVariablePropertyChange: onPluginVariablePropertyChange, onPluginNewVariable: onPluginNewVariable, onPluginDeleteVariable: onPluginDeleteVariable, onPluginVariableAddEnumValue: onPluginVariableAddEnumValue, onPluginVariableUpdateEnumValue: onPluginVariableUpdateEnumValue, onPluginVariableDeleteEnumValue: onPluginVariableDeleteEnumValue }) })) })) }) }, plugin.id)); })] }));
}
