var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import useExternalJwtAuth from "../../data/hooks/useExternalJwtAuth";
import { useEffect } from "react";
import View from "../styledComponents/View";
import ReactLoading from "react-loading";
function ProtectedRoute(_a) {
    var children = _a.children;
    var _b = useExternalJwtAuth(), isJwtLoading = _b.isJwtLoading, isJwtAuthenticated = _b.isJwtAuthenticated, fetchUserInfo = _b.fetchUserInfo;
    useEffect(function () {
        if (!location.pathname.startsWith("/external-auth")) {
            fetchUserInfo();
        }
    }, [location.pathname]);
    // Render loading spinner while authentication is in progress
    if (isJwtLoading) {
        return (_jsx(View, __assign({ height: "100vh", flex: true, aCenter: true, jCenter: true }, { children: _jsx(ReactLoading, { type: "spin", height: 30, width: 30 }) })));
    }
    // If authenticated, render the child components
    if (isJwtAuthenticated) {
        return _jsx(_Fragment, { children: children });
    }
    return null;
}
export default ProtectedRoute;
