var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from "react";
import { Stack, FormLabel, Grid, Box, } from "@mui/joy";
import Variable from "./components/Variable";
function Variables(props) {
    var variables = props.variables, pluginIndex = props.pluginIndex, invalidFields = props.invalidFields, onPluginVariablePropertyChange = props.onPluginVariablePropertyChange, onPluginNewVariable = props.onPluginNewVariable, onPluginDeleteVariable = props.onPluginDeleteVariable, onPluginVariableAddEnumValue = props.onPluginVariableAddEnumValue, onPluginVariableUpdateEnumValue = props.onPluginVariableUpdateEnumValue, onPluginVariableDeleteEnumValue = props.onPluginVariableDeleteEnumValue;
    return (_jsx(Stack, __assign({ spacing: 2 }, { children: variables.length > 0 && (_jsxs(Box, __assign({ sx: { flexGrow: 1 } }, { children: [_jsxs(Grid, __assign({ container: true, spacing: 1, alignItems: "center", wrap: "nowrap" }, { children: [_jsx(Grid, { xs: 0.5, sx: { ml: 0, pl: 0.3, minWidth: "55px" } }), _jsx(Grid, __assign({ xs: 2 }, { children: _jsx(FormLabel, { children: "Name" }) })), _jsx(Grid, __assign({ xs: 2 }, { children: _jsx(FormLabel, { children: "Label" }) })), _jsx(Grid, __assign({ xs: 2 }, { children: _jsx(FormLabel, { children: "Type" }) })), _jsx(Grid, __assign({ xs: 5 }, { children: _jsx(FormLabel, { children: "Question (Optional)" }) })), _jsx(Grid, { xs: 0.5 })] })), variables.map(function (variable, varIndex) { return (_jsx(React.Fragment, { children: _jsx(Variable, { variable: variable, varIndex: varIndex, pluginIndex: pluginIndex, invalidFields: invalidFields, onPluginVariablePropertyChange: onPluginVariablePropertyChange, onPluginDeleteVariable: onPluginDeleteVariable, onPluginVariableAddEnumValue: onPluginVariableAddEnumValue, onPluginVariableUpdateEnumValue: onPluginVariableUpdateEnumValue, onPluginVariableDeleteEnumValue: onPluginVariableDeleteEnumValue }) }, varIndex)); })] }))) })));
}
export default memo(Variables);
