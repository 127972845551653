var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTwilio } from "../../../data/hooks/useTwilio";
import { Device, Call } from "@twilio/voice-sdk";
import { Button } from "@mui/joy";
export var TwilioCallComponent = function (_a) {
    var flowId = _a.flowId;
    var _b = useState(null), connection = _b[0], setConnection = _b[1];
    var _c = useState(), device = _c[0], setDevice = _c[1];
    var _d = useState(false), callInProgress = _d[0], setCallInProgress = _d[1];
    var _e = useTwilio(), twilioCallSetupData = _e.twilioCallSetupData, getTwilioCallSetupData = _e.getTwilioCallSetupData;
    useEffect(function () {
        getTwilioCallSetupData(flowId);
    }, [flowId]);
    useEffect(function () {
        if (twilioCallSetupData === null || twilioCallSetupData === void 0 ? void 0 : twilioCallSetupData.token) {
            try {
                var newDevice = new Device(twilioCallSetupData.token, {
                    codecPreferences: [Call.Codec.Opus],
                });
                newDevice.on("ready", function () {
                    console.log("Twilio.Device is ready");
                });
                newDevice.on("error", function (error) {
                    console.error("Twilio.Device error:", error);
                    setCallInProgress(false);
                    setConnection(null);
                });
                newDevice.on("disconnect", function (conn) {
                    console.log("Call ended.", conn);
                    setCallInProgress(false);
                    setConnection(null);
                });
                setDevice(newDevice);
            }
            catch (error) {
                setCallInProgress(false);
                setConnection(null);
                console.error("Error initializing Twilio Device:", error);
            }
        }
    }, [twilioCallSetupData === null || twilioCallSetupData === void 0 ? void 0 : twilioCallSetupData.token]);
    var makeCall = function () {
        if (device) {
            device
                .connect()
                .then(function (conn) {
                setConnection(conn);
                setCallInProgress(true);
            })
                .catch(function (error) {
                console.error("Error making call:", error);
            });
        }
        else {
            console.error("Device is not ready");
        }
    };
    var endCall = function () {
        if (connection) {
            connection.disconnect();
            setCallInProgress(false);
        }
        else {
            console.error("No active call to end");
        }
    };
    return (_jsx("div", { children: callInProgress ? (_jsx(Button, __assign({ variant: "solid", onClick: endCall, sx: {
                width: "89px",
                height: "37px",
                backgroundColor: "#2C4972",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px",
                radius: "7px"
            } }, { children: "End Call" }))) : (_jsx(Button, __assign({ variant: "solid", onClick: makeCall, sx: {
                width: "89px",
                height: "37px",
                backgroundColor: "#2C4972",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px",
                radius: "7px"
            } }, { children: "Test Call" }))) }));
};
