var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import ReactLoading from 'react-loading';
import { colors } from '../../styles/theme';
import View from '../../components/styledComponents/View';
import useExternalJwtAuth from '../../data/hooks/useExternalJwtAuth';
function JwtExternalAuth() {
    var _a = useExternalJwtAuth(), isJwtLoading = _a.isJwtLoading, authenticate = _a.authenticate;
    useEffect(function () {
        authenticate();
    }, []);
    if (isJwtLoading) {
        return (_jsx(View, __assign({ height: "100vh", flex: true, aCenter: true, jCenter: true }, { children: _jsx(ReactLoading, { type: "spin", color: colors.theme, height: 30, width: 30 }) })));
    }
    return (_jsx(_Fragment, {}));
}
export default JwtExternalAuth;
