var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import * as Sentry from "@sentry/react";
import { BrowserRouter } from "react-router-dom";
import { BrowserTracing } from "@sentry/tracing";
import { SkeletonTheme } from "react-loading-skeleton";
import { APP_ENV } from "./constants/index";
import { StyledEngineProvider } from "@mui/joy/styles";
import * as React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import './index.css';
import { colors } from "./styles/theme";
import GlobalStyles from "./styles/globalStyles";
import "react-loading-skeleton/dist/skeleton.css";
if ((APP_ENV === null || APP_ENV === void 0 ? void 0 : APP_ENV.trim().toLowerCase()) === "PRODUCTION".trim().toLowerCase()) {
    Sentry.init({
        dsn: "https://f662257a3dc94c30873cb042284df6a2@o590385.ingest.sentry.io/4504728942084096",
        integrations: [new BrowserTracing(), new Sentry.Replay()],
        beforeSend: function (event, hint) {
            // Check if it is an exception, and if so, show the report dialog
            if (event.exception) {
                //Sentry.showReportDialog({ eventId: event.event_id });
                //Do nothing
            }
            return event;
        },
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
    });
}
var root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
// <Auth0Provider
//   domain={process.env.REACT_APP_AUTH0_DOMAIN!}
//   clientId={process.env.REACT_APP_AUTH0_CLIENTID!}
//   authorizationParams={{
//     audience: `${process.env.REACT_APP_AUTH0_AUDIENCE!}`,
//     redirect_uri: window.location.origin + "/auth",
//   }}
// >
_jsx(RecoilRoot, { children: _jsxs(BrowserRouter, { children: [_jsx(GlobalStyles, {}), _jsx(SkeletonTheme, __assign({ baseColor: colors.slate, highlightColor: colors.light }, { children: _jsx(React.StrictMode, { children: _jsx(StyledEngineProvider, __assign({ injectFirst: true }, { children: _jsx(App, {}) })) }) }))] }) })
// // {/* </Auth0Provider> */}
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
