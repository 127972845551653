var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useCallback, useEffect, useState } from "react";
import { FormControl, Input, Grid, Select, IconButton, Option, Modal, ModalDialog, ModalClose, DialogTitle, DialogContent, Stack, Button, FormLabel, Tooltip, Switch, } from "@mui/joy";
import DeleteIcon from "@mui/icons-material/Delete";
import TuneIcon from "@mui/icons-material/Tune";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import HearingOutlinedIcon from "@mui/icons-material/HearingOutlined"; // for Dictation mode
import SpellcheckOutlinedIcon from "@mui/icons-material/SpellcheckOutlined"; // for Check Spelling
import EmergencyOutlinedIcon from "@mui/icons-material/EmergencyOutlined";
import React from "react";
function Variable(props) {
    var variable = props.variable, pluginIndex = props.pluginIndex, varIndex = props.varIndex, invalidFields = props.invalidFields, onPluginVariablePropertyChange = props.onPluginVariablePropertyChange, onPluginDeleteVariable = props.onPluginDeleteVariable, onPluginVariableAddEnumValue = props.onPluginVariableAddEnumValue, onPluginVariableUpdateEnumValue = props.onPluginVariableUpdateEnumValue, onPluginVariableDeleteEnumValue = props.onPluginVariableDeleteEnumValue;
    var _a = useState(false), isRegexModalOpen = _a[0], setIsRegexModalOpen = _a[1];
    var _b = useState(false), isEnumModalOpen = _b[0], setIsEnumModalOpen = _b[1];
    var _c = useState(""), formatRegex = _c[0], setFormatRegex = _c[1];
    var _d = useState(false), isSettingsModalOpen = _d[0], setIsSettingsModalOpen = _d[1];
    var openSettingsModal = function () { return setIsSettingsModalOpen(true); };
    var closeSettingsModal = function () { return setIsSettingsModalOpen(false); };
    var saveSettings = function () {
        setIsSettingsModalOpen(false);
    };
    var closeFormatRegexModal = useCallback(function () {
        setIsRegexModalOpen(false);
    }, []);
    var saveFormatRegex = useCallback(function () {
        onPluginVariablePropertyChange(pluginIndex, varIndex, "formatRegex", formatRegex);
        setIsRegexModalOpen(false);
    }, [formatRegex, onPluginVariablePropertyChange, pluginIndex, varIndex]);
    var closeEnumModal = useCallback(function () {
        setIsEnumModalOpen(false);
    }, []);
    useEffect(function () {
        if (isRegexModalOpen) {
            setFormatRegex(variable.formatRegex);
        }
    }, [isRegexModalOpen, variable.formatRegex]);
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, __assign({ container: true, spacing: 2, alignItems: "center", wrap: "nowrap" }, { children: [_jsx(Grid, __assign({ xs: 0.5, sx: { ml: 0, pl: 0.3, minWidth: "55px" } }, { children: _jsxs(Stack, __assign({ direction: "row", spacing: 0.2 }, { children: [variable.isRequired && (_jsx(Tooltip, __assign({ title: "Required" }, { children: _jsx(EmergencyOutlinedIcon, { fontSize: "small" }) }))), variable.isDictationModeEnabled && (_jsx(Tooltip, __assign({ title: "Dictation Mode Enabled" }, { children: _jsx(HearingOutlinedIcon, { fontSize: "small" }) }))), variable.shouldCheckSpelling && (_jsx(Tooltip, __assign({ title: "Check Spelling Enabled" }, { children: _jsx(SpellcheckOutlinedIcon, { fontSize: "small" }) })))] })) })), _jsx(Grid, __assign({ xs: 2 }, { children: _jsx(FormControl, __assign({ sx: { display: { sm: "flex-column", md: "flex-row" }, gap: 2 } }, { children: _jsx(Input, { size: "sm", placeholder: "Name", disabled: true, error: invalidFields.some(function (e) {
                                    return e.field ===
                                        "plugins[".concat(pluginIndex, "].variables[").concat(varIndex, "].name");
                                }), value: variable.name, onChange: function (event) {
                                    return onPluginVariablePropertyChange(pluginIndex, varIndex, "name", event.target.value);
                                } }) })) })), _jsx(Grid, __assign({ xs: 2 }, { children: _jsx(FormControl, __assign({ sx: { display: { sm: "flex-column", md: "flex-row" }, gap: 2 } }, { children: _jsx(Input, { size: "sm", placeholder: "Label", disabled: true, error: invalidFields.some(function (e) {
                                    return e.field ===
                                        "plugins[".concat(pluginIndex, "].variables[").concat(varIndex, "].label");
                                }), value: variable.label, onChange: function (event) {
                                    return onPluginVariablePropertyChange(pluginIndex, varIndex, "label", event.target.value);
                                } }) })) })), _jsx(Grid, __assign({ xs: 2 }, { children: _jsxs(FormControl, __assign({ sx: { display: "flex", flexDirection: "row", alignItems: "center" } }, { children: [_jsxs(Select, __assign({ size: "sm", disabled: true, placeholder: "Type", value: variable.type, sx: { flexGrow: 1 }, onChange: function (event, newValue) {
                                        return onPluginVariablePropertyChange(pluginIndex, varIndex, "type", newValue);
                                    } }, { children: [_jsx(Option, __assign({ value: "string" }, { children: "String" })), _jsx(Option, __assign({ value: "int32" }, { children: "Integer" })), _jsx(Option, __assign({ value: "decimal" }, { children: "Decimal" })), _jsx(Option, __assign({ value: "datetime" }, { children: "Date and Time" })), _jsx(Option, __assign({ value: "phonenumber" }, { children: "Phone Number" })), _jsx(Option, __assign({ value: "enum" }, { children: "Enum" }))] })), variable.type === "string" && (_jsx(IconButton, __assign({ size: "sm", disabled: true, onClick: function () { return setIsRegexModalOpen(true); } }, { children: _jsx(TuneIcon, {}) }))), variable.type === "enum" && (_jsx(IconButton, __assign({ size: "sm", disabled: true, onClick: function () { return setIsEnumModalOpen(true); } }, { children: _jsx(TuneIcon, {}) })))] })) })), _jsx(Grid, __assign({ xs: 5 }, { children: _jsx(FormControl, __assign({ sx: { display: { sm: "flex-column", md: "flex-row" }, gap: 2 } }, { children: _jsx(Input, { size: "sm", placeholder: "Question", color: "primary", disabled: !variable.isEnabled, value: variable.question, onChange: function (event) {
                                    return onPluginVariablePropertyChange(pluginIndex, varIndex, "question", event.target.value);
                                } }) })) })), _jsx(Grid, __assign({ xs: 0.5 }, { children: _jsx(IconButton, __assign({ size: "sm", onClick: openSettingsModal }, { children: _jsx(SettingsIcon, {}) })) }))] })), _jsx(_Fragment, { children: _jsx(Modal, __assign({ open: isSettingsModalOpen, onClose: closeSettingsModal, hideBackdrop: true }, { children: _jsxs(ModalDialog, __assign({ variant: "outlined" }, { children: [_jsx(ModalClose, { onClick: closeSettingsModal }), _jsx(DialogTitle, { children: "Settings" }), _jsx(DialogContent, __assign({ sx: { mt: 2 } }, { children: _jsxs(Stack, __assign({ spacing: 2 }, { children: [_jsx(FormControl, { children: _jsxs(Stack, __assign({ direction: "row", alignItems: "center", spacing: 1 }, { children: [_jsx(FormLabel, { children: "Enabled" }), _jsx(Tooltip, __assign({ title: "The agent will skip this question if disabled." }, { children: _jsx(InfoOutlinedIcon, { fontSize: "medium", color: "primary", sx: { ml: 0.2, mr: 1 } }) })), _jsx(Switch, { checked: variable.isEnabled, size: "sm", onChange: function (event) {
                                                            return onPluginVariablePropertyChange(pluginIndex, varIndex, "isEnabled", event.target.checked);
                                                        } })] })) }), _jsx(FormControl, { children: _jsxs(Stack, __assign({ direction: "row", alignItems: "center", spacing: 1 }, { children: [_jsx(FormLabel, { children: "Required" }), _jsx(Tooltip, __assign({ title: "An answer to this question will be required before the consumer can move forward with the conversation." }, { children: _jsx(InfoOutlinedIcon, { fontSize: "medium", color: "primary", sx: { ml: 0.2, mr: 1 } }) })), _jsx(Switch, { checked: variable.isRequired, size: "sm", onChange: function (event) {
                                                            return onPluginVariablePropertyChange(pluginIndex, varIndex, "isRequired", event.target.checked);
                                                        } })] })) }), _jsx(FormControl, { children: _jsxs(Stack, __assign({ direction: "row", alignItems: "center", spacing: 1 }, { children: [_jsx(FormLabel, { children: "Dictation Mode" }), _jsx(Tooltip, __assign({ title: "Allows users more time to respond and automatically applies corrections for enhanced accuracy." }, { children: _jsx(InfoOutlinedIcon, { fontSize: "medium", color: "primary", sx: { ml: 0.2, mr: 1 } }) })), _jsx(Switch, { checked: variable.isDictationModeEnabled, size: "sm", onChange: function (event) {
                                                            return onPluginVariablePropertyChange(pluginIndex, varIndex, "isDictationModeEnabled", event.target.checked);
                                                        } })] })) }), _jsx(FormControl, { children: _jsxs(Stack, __assign({ direction: "row", alignItems: "center", spacing: 1 }, { children: [_jsx(FormLabel, { children: "Check Spelling" }), _jsx(Tooltip, __assign({ title: "The agent will spell out words using phonetic cues (e.g., 'A as in Alpha, B as in Bravo') to confirm details, such as email addresses or names, with the user." }, { children: _jsx(InfoOutlinedIcon, { fontSize: "medium", color: "primary", sx: { ml: 0.2, mr: 1 } }) })), _jsx(Switch, { checked: variable.shouldCheckSpelling, size: "sm", onChange: function (event) {
                                                            return onPluginVariablePropertyChange(pluginIndex, varIndex, "shouldCheckSpelling", event.target.checked);
                                                        } })] })) }), _jsx(Button, __assign({ sx: { width: 60 }, variant: "soft", onClick: saveSettings }, { children: "OK" }))] })) }))] })) })) }), variable.type == "string" && (_jsx(Modal, __assign({ open: isRegexModalOpen, onClose: closeFormatRegexModal, hideBackdrop: true }, { children: _jsxs(ModalDialog, __assign({ variant: "outlined" }, { children: [_jsx(ModalClose, {}), _jsx(DialogTitle, { children: "RegEx Validation" }), _jsx(DialogContent, __assign({ sx: { py: 2 } }, { children: _jsxs(Stack, __assign({ spacing: 2 }, { children: [_jsx(Input, { autoFocus: true, value: formatRegex, disabled: true, onChange: function (event) { return setFormatRegex(event.target.value); } }), _jsx(Button, __assign({ variant: "soft", onClick: saveFormatRegex }, { children: "OK" }))] })) }))] })) }))), variable.type == "enum" && (_jsx(Modal, __assign({ open: isEnumModalOpen, onClose: closeEnumModal, hideBackdrop: true }, { children: _jsxs(ModalDialog, __assign({ variant: "outlined" }, { children: [_jsx(ModalClose, {}), _jsx(DialogTitle, { children: "Enum Values" }), _jsxs(DialogContent, __assign({ sx: { py: 2 } }, { children: [Object.keys(variable.enumValues || []).length > 0 && (_jsxs(Grid, __assign({ spacing: 1, container: true }, { children: [_jsx(Grid, __assign({ xs: 5 }, { children: _jsx(FormLabel, { children: "Value" }) })), _jsx(Grid, __assign({ xs: 6 }, { children: _jsx(FormLabel, { children: "Label (Optional)" }) })), _jsx(Grid, { xs: 1 }), variable.enumValues.map(function (val, enumIndex) { return (_jsxs(React.Fragment, { children: [_jsx(Grid, __assign({ xs: 5 }, { children: _jsx(Input, { size: "sm", value: val.value, onChange: function (event) {
                                                            return onPluginVariableUpdateEnumValue(pluginIndex, varIndex, enumIndex, event.target.value, "value");
                                                        } }) })), _jsx(Grid, __assign({ xs: 6 }, { children: _jsx(Input, { size: "sm", value: val.display, onChange: function (event) {
                                                            return onPluginVariableUpdateEnumValue(pluginIndex, varIndex, enumIndex, event.target.value, "display");
                                                        } }) })), _jsx(Grid, __assign({ xs: 1 }, { children: _jsx(IconButton, __assign({ size: "sm", onClick: function () {
                                                            return onPluginVariableDeleteEnumValue(pluginIndex, varIndex, enumIndex);
                                                        } }, { children: _jsx(DeleteIcon, {}) })) }))] }, enumIndex)); })] }))), _jsx(Stack, { children: _jsx(Button, __assign({ size: "sm", color: "primary", variant: "plain", startDecorator: _jsx(AddIcon, {}), onClick: function () {
                                            return onPluginVariableAddEnumValue(pluginIndex, varIndex);
                                        }, sx: { alignSelf: "flex-start" } }, { children: "Add new pair" })) }), _jsx(Stack, __assign({ alignItems: "center" }, { children: _jsx(Button, __assign({ variant: "soft", onClick: closeEnumModal, sx: { width: "200px" } }, { children: "OK" })) }))] }))] })) })))] }));
}
export default memo(Variable);
