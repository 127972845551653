import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { useColorScheme } from "@mui/joy/styles";
import Switch from "@mui/joy/Switch";
export default function ColorSchemeToggle() {
    var _a = useColorScheme(), mode = _a.mode, setMode = _a.setMode;
    var _b = React.useState(false), mounted = _b[0], setMounted = _b[1];
    React.useEffect(function () {
        setMounted(true);
    }, []);
    if (!mounted) {
        return null; // Avoid rendering until mounted
    }
    var handleToggle = function () {
        if (mode === "light") {
            setMode("dark");
        }
        else {
            setMode("light");
        }
    };
    return (_jsx(Switch, { checked: mode === "dark", onChange: handleToggle, size: "sm", sx: { display: "flex", alignItems: "center" } }));
}
