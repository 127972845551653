var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Box from "@mui/joy/Box";
import { useJune } from "./data/hooks/useJune";
import ProtectedRoute from "./components/ProtectedRoute";
import { AIAgents } from "./pages/AIAgents";
import JwtExternalAuth from "./pages/JwtExternalAuth";
function Main() {
    var analytics = useJune();
    var location = useLocation();
    useEffect(function () {
        if (analytics) {
            analytics.page();
        }
    }, [location, analytics]);
    return (_jsx(Box, __assign({ component: "main", className: "MainContent", sx: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            height: "100dvh",
            gap: 0,
            pt: "calc(var(--TopNavBar-height) - 6px)", // This should account for navbar height 8 px is padding 
        } }, { children: _jsxs(Routes, { children: [_jsx(Route, { index: true, element: _jsx(ProtectedRoute, { children: _jsx(Navigate, { to: "/ai-agents", replace: true }) }) }), _jsx(Route, { path: "/ai-agents", element: _jsx(ProtectedRoute, { children: _jsx(AIAgents, {}) }) }), _jsx(Route, { path: "/external-auth/:systemId/:userId", element: _jsx(JwtExternalAuth, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/" }) })] }) })));
}
export default Main;
